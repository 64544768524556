const AboutHeaderComp = () => {
    return(
        <>
        <section className="about-header-section">
            <div className="about-header-content">
                <h2>About Page</h2>
                {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius deleniti labore iste perspiciatis odit exercitationem alias quisquam accusantium, aperiam blanditiis facere facilis minima! Totam sint accusamus laborum, magni tenetur aspernatur?
                </p> */}
            </div>
        </section>
        </>
    )
}

export default AboutHeaderComp