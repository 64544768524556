import '../../assets/css/about/About.css'
import AboutComp from '../../components/about/AboutFullComp'
import AboutHeaderComp from '../../components/about/AboutHeaderComp'

const AboutPage = () => {
    return(
        <>
        <AboutHeaderComp></AboutHeaderComp>
        <AboutComp></AboutComp>
        </>
    )
}

export default AboutPage