const ContactHeaderComp = () => {
    return(
        <>
        <section className="contact-header-section">
            <div className="contact-header-content">
                <h2>Contact Page</h2>
            </div>
        </section>
        </>
    )
}

export default ContactHeaderComp